<template>
  <div class="exReport_container">
    <div class="top">体检报告查询</div>
    <div class="search">
      <van-field v-model="userName" placeholder="姓名" input-align="left" @input="getName" class="input" />
      <van-field v-model="cardNo" placeholder="请输入身份证号" input-align="left" @input="getCardNo" class="input" />
      <van-field v-model="tel" placeholder="请输入电话号码" input-align="left" @input="getTel" class="input" />
      <van-field v-model="Captcha" placeholder="验证码" input-align="left" @input="getCode" class="input" />
      <van-button type="info" @click="getCaptCha" class="searchBtn">获取验证码</van-button>
      <van-button type="info" @click="peReportListForAdult" class="searchBtn">查询</van-button>
    </div>
    <div class="list">
      <van-cell v-for="(item, index) in exReportList" :key="index" :title="'体检套餐：' + item.packageName"
        :label="'体检时间：' + item.reportTime" is-link @click="navList(item.reportNo)" />
    </div>
    <van-empty description="暂无内容" v-if="exReportList === ''" />
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "ExReport",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      exReportList: [],
      cardNo: '',
      userName: '',
      tel: '',
      Captcha: ''
    };
  },
  created () {
    if (window.localStorage.getItem("userName")) {
      this.userName = window.localStorage.getItem("userName")
      this.tel = window.localStorage.getItem("tel")
      this.cardNo = window.localStorage.getItem("cardNo")
    }
  },
  methods: {
    //获取体检报告列表(成人)
    async peReportListForAdult () {
      if (this.userName === '') {
        this.$toast.fail('姓名不能为空');
      } else if (this.cardNo === '') {
        this.$toast.fail('身份证不能为空');
      } else if (!/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(this.cardNo)) {
        this.$toast.fail('身份证有误');
      } else if (this.tel === '' || !/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.tel)) {
        this.$toast.fail('请输入正确的电话号码');
      } else if (this.Captcha === '') {
        this.$toast.fail('请输入验证码');
      }
      else {
        this.$toast.loading({
          duration: 0,
          message: "加载中",
          forbidClick: true
        });
        window.localStorage.setItem("userName", this.userName);
        window.localStorage.setItem("cardNo", this.cardNo);
        window.localStorage.setItem("tel", this.tel);
        const { data: res } = await jsonPost("/wxapp/pe/peReportList", {
          patCardNo: this.cardNo,
          phone: this.tel,
          Captcha: this.Captcha
        });
        this.$toast.clear();
        if (res.code === 0) {
          this.exReportList = res.data.item;
        } else {
          this.exReportList = "";
          this.$toast.fail(res.msg);
        }
      }

    },
    async getCaptCha () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/pe/sendSms", {
        phone: this.tel
      });
      if (res.code === 0) {
        this.$toast.clear();
        this.$toast.success(res.msg);
      } else {
        this.$toast.clear();
        this.$toast.fail(res.msg);
      }
    },
    getCardNo (value) {
      this.cardNo = value;
    },
    getName (value) {
      this.userName = value;
    },
    getTel (value) {
      this.tel = value;
    },
    getCode (value) {
      this.Captcha = value;
    },
    navList (reportNo) {
      this.$router.push({
        path: "/exReport/list",
        query: {
          reportNo: reportNo
        }
      });
    }
  }
};
</script>
<style scope>
.exReport_container .header {
  background-color: white;
}

.top {
  margin: 30px;
  font-size: 50px;
}

.search {}

.search .van-field {
  width: 70%;
}

.van-button {
  margin: 10px;
  width: 96%;
}

.exReport_container .header .van-cell {
  border-bottom: 1px solid rgb(201, 191, 191);
  padding: 30px;
}

.exReport_container .header .van-cell::after {
  border: 0;
}

.exReport_container .header .van-field__label {
  text-align: left;
  font-size: 40px;
}

.exReport_container .header .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.exReport_container .header .van-icon {
  font-size: 40px;
}

.exReport_container .header .van-picker-column {
  font-size: 40px;
}

.exReport_container .header .van-picker__cancel,
.exReport_container .header .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.list {
  padding-bottom: 60px;
}

.list .van-cell {
  font-size: 40px !important;
  padding: 30px !important;
  border-bottom: 1px solid #f5f6f5;
}

.list .van-cell .van-cell__label {
  font-size: 36px !important;
  margin-top: 30px !important;
}

.list .van-cell .van-icon {
  font-size: 40px !important;
}
</style>